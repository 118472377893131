<template>
  <div class="collection-item-border">
    <div class="collection-item-theme collection-theme-8" @click="handleClick">
      <div class="collection-item-content">
        <div class="content-row">
          <div class="ctag">
            Popular
          </div>
          <div class="title">
            {{ item.title }}
          </div>
        </div>
        <div class="news-info-row">
          <div class="new-info-author">
            {{ item.channel }}
          </div>
          ·
          <div class="new-info-time">
            {{ $global.timeAgo($global.dateStringToTimestamp(item.published)) }}
          </div>
        </div>
      </div>
      <div class="collection-item-sources">
        <img v-lazy="item.headImg?.[0]" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import '@/css/module/collection/theme_8.scss';

export default {
  props: {
    item: {
      require: true,
      type: Object,
      default () {
        return {}
      }
    },
  },
  methods: {
    handleClick () {
      this.$emit('customClick');
    }
  }
};
</script>
